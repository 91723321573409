<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 40%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }"
                          :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')"
                          v-model="userdepidCascaderSelected"
                          :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll"
                          placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="组名" prop="name" style="width: 40%">
              <a-input v-model="queryParams.name"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开-->
<!--                <a-icon :type="showAdvanced?'up':'down'"></a-icon>-->
<!--              </a-button>-->
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">能耗表组设置</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新增
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :customRow="customRow" :columns="tableColumns" :data-source="tableData" row-key="id"
                 :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
                <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                  <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                </a-tooltip>
              </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {
  deleteOneEcGroup,
  getEcGroupListByCondition,
} from "A/energyconsumption";
import addOrEditModal from "./addOrEditModal";
import ecCommont from "../mixins/ecMixin"

export default {
  name: "directories",
  mixins: [deptselect, pagination, ecCommont],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams: {
        name: '',
      },
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'userdepname',
          key: 'userdepname',
        },
        {
          title: '组名',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '创建人',
          dataIndex: 'username',
          key: 'username',
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          key: 'create_time',
          scopedSlots: {customRender: 'create_time'}
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'operation'},
          width: 70
        },
      ],
      userdepidCascaderSelected: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      //操作按钮权限
      btnList: [],
      menu: {},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "能耗表组设置"
    },
    breadcrumb() {
      const pages = [{name: "设施设备管理", path: ""}]
      pages.push({name: "运行管理", path: ""})
      pages.push({name: "能耗管理", path: ""})
      pages.push({name: this.pageTitle, path: ""})
      return pages
    },
  },
  watch: {
    userdepidCascaderSelected(val) {
      if (val && val.length) {
        this.queryParams.userdepsid = val[val.length - 1];
      } else {
        this.queryParams.userdepsid = '';
      }
    },
    'queryParams.type'(val) {
      if (val && val.length) {
        this.ecMeterFormAllList = this.ecMeterForm[val];
        this.ecGradeList = this.ecMeterLevel[val]
      } else {
        this.ecMeterFormAllList = []
        this.ecGradeList = []
      }
    },
  },
  created() {
    this.menu = getItemFromArrayByKey(this.operationMenuTree, "menuid", this.$route.params.menuid, "children")
    for (let i = 0; i < this.menu.children.length; i++) {//获取菜单页面内操作权限
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods: {
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      console.log(this.queryParams)
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEcGroupListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected = []
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'id', id);
      if (type == 'delete') {
        this.deleteConfirm(id, record)
      }else {
        this.showModal(type, record)
      }
    },
    showModal(type, record) {
      if(type === 'add'){
        this.modalVisible=true
        this.modalShowType=type
      } else if (type === 'detail') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      } else if (type === 'edit') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      }
    },
    exportModal() {
      this.$confirm('你确认要按照所选筛选条件导出文件吗？', {
        type: 'warning',
        centered: true,
      }).then(() => {
        this.exportBusDevice()
      }).catch(() => {
      });
    },
    exportBusDevice() {
      let params = {
        ...this.queryParams,
        is_history: '0',
      }
      this.showLoading();
      exportBusDeviceExcel(params).then((res) => {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(() => {
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?', {
        type: 'warning',
        centered: true,
      }).then(() => {
        this.delete(record.id);
      }).catch(() => {

      });
    },
    delete(id) {
      this.showLoading();
      if (id) {
        let params = {
          id
        };
        deleteOneEcGroup(params).then(res => {
          this.hideLoading();
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    customRow(record, index) {
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData = record
            this.modalShowType = "detail"
            this.modalVisible = true
          },
        },
      }
    },
  },
}
</script>
<style scoped>

</style>